
import state from "./moduleInternalRegulationState.js"
import mutations from "./moduleInternalRegulationMutations.js"
import getters from "./moduleInternalRegulationGetters.js"
import actions from './moduleInternalRegulationActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
