export default {
  ADD_ITEM(state, item) {
    debugger;
    state.InternalRegulation.unshift(item);
  },
  SET_InternalRegulation(state, InternalRegulation) {
    debugger;
    state.InternalRegulation = InternalRegulation;

  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.InternalRegulations.findIndex((p) => p.ID == itemId)
    state.InternalRegulations.splice(ItemIndex, 1)
},
};

