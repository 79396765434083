<template>
    <div>
      <div id="data-list-list-view" class="data-list-container">
        <div>
    <vue-editor style="height: 400px;" v-model="InternalRegulations.internalRegulations" />
    <br>
    <vs-button class="mr-6" style="margin-top: 40px;"  @click="submitData">{{ $t('save') }}</vs-button>
 </div>

<hr > 


<!-- <br>
<textarea v-model="textInput.internalRegulation"></textarea>
<vs-button class="mr-6" @click="submitData">{{ $t('Submit') }}</vs-button>




<hr >   
<h1  style="  text-align:center;">الائحة الداخلية</h1>   
<vs-table
          ref="table"
          id="Scroll"
          pagination
          :max-items="itemsPerPage"
          
          :data="InternalRegulations"
        >

  
  
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <vs-button type="border" icon-pack="feather" @click="addNewData">{{
              $t("AddNew")
            }}</vs-button>
  
           
          </div>
  
          <template slot="thead">
            <vs-th sort-key="NameEn">{{ $t("النقاط") }}</vs-th>
           

          </template>
  
          <template slot-scope="{ data }">
            <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
              class="table-row"
            >
              <vs-td class="product-name font-medium truncate">
                <p>{{ tr.internalRegulations }}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap edit-actions">
                <feather-icon
                  icon="EditIcon"
                  @click.stop="editData(tr)"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                />
                <feather-icon
                  icon="TrashIcon"
                  @click.stop="openConfirm(tr)"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                />
              </vs-td>
            </tr>
          </tbody>
          </template>
        </vs-table> -->
       
      </div>
    </div>
  </template>
  
  <script>
  import { domain } from "@/gloabelConstant.js";
  import moduleInternalRegulation from "@/store/settings/InternalRegulation/moduleInternalRegulation.js";
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      VueEditor
    },
  
    data() {
      return {
        itemsPerPage: 10,
       // HotLines: [],
        baseURL: domain,
        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},
        rowDataForDelete: {},
        activeConfirm: false,
        textInput:{
          Id : null,
          internalRegulation: ''
        },

      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      InternalRegulations() {
        debugger;
        return this.$store.state.InternalRegulationList.InternalRegulation;
      }
    },
  
    methods: {
      initValues() {
      if (this.data.id) return;
      this.Id = null;
      this.ShowOffersWithNoRestrict = false;
      debugger;
      // this.textInput={
      //   Id : null,
      //     internalRegulation: ''
      //   };
    },
      submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          debugger;
          var x = this.InternalRegulations.id ;
          const obj = {
            Id: x === undefined ? null : x,
            InternalRegulation: this.InternalRegulations.internalRegulations,
           
          };
            this.$store.dispatch("InternalRegulationList/addItem", obj).then(() => {
              debugger;
              // this.textInput={
              //     internalRegulation: ''
              //   };
              debugger;
              this.$store
                .dispatch("InternalRegulationList/fetchDataListItems") .then(() => {
                  debugger;
                //   this.textInput={
                //     Id: this.InternalRegulations.Id,
                //      internalRegulation: this.InternalRegulations.InternalRegulations
                // };
                })
              window.showSuccess();
                      
            }).catch(err => {
              console.error(err);
            });
            debugger;
          }
        });
      },
  
      openConfirm(data) {
        debugger;
        this.rowDataForDelete = data;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: this.$t("DeleteConfirmText"),
          accept: this.acceptAlert,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      },
      acceptAlert() {
        this.deleteData(this.rowDataForDelete);
        this.$vs.notify({
          color: "success",
          title: "Deleted record",
          text: this.$t("DeletedSuccessfully"),
        });
      },
      deleteData(data) {
      debugger;
      this.$store
        .dispatch("InternalRegulationList/removeItem", data)
        .then(() => {
          this.$store.dispatch("InternalRegulationList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("InternalRegulationList/fetchDataListItems");

        });
    },
    editData(data) {
        debugger;
        this.textInput={
                   Id: data.id,
                  internalRegulation: data.internalRegulations
                };
                debugger;
       
      },
     
    },
    created() {
      if (!moduleInternalRegulation.isRegistered) {
        this.$store.registerModule("InternalRegulationList", moduleInternalRegulation);
        moduleInternalRegulation.isRegistered = true;
      }
  
      this.$store.dispatch("InternalRegulationList/fetchDataListItems")
       
    },
    mounted() {
      this.isMounted = true;
    },
  };
  </script>
  
<style lang="scss">
    #data-list-list-view small {
      color: rgb(255, 115, 0);
      text-decoration: underline rgb(255, 115, 0);
    }
    
    .vxCard:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0%;
      margin-left: 10%;
      z-index: 500000;
      width: 25%;
      height: 20%;
      border-top: 3px solid rgb(255, 115, 0) !important;
      border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
      border-right: 0 none rgba(255, 0, 0, 0.9) !important;
      border-left: 0 none rgba(0, 0, 0, 0.9) !important;
    }
    #data-list-list-view {
      .vs-con-table {
        .product-name {
          max-width: 23rem;
        }
    
        .vs-table--header {
          display: flex;
          flex-wrap: wrap-reverse;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          > span {
            display: flex;
            flex-grow: 1;
          }
    
          .vs-table--search {
            padding-top: 0;
    
            .vs-table--search-input {
              padding: 0.9rem 2.5rem;
              font-size: 1rem;
    
              & + i {
                left: 1rem;
              }
    
              &:focus + i {
                left: 1rem;
              }
            }
          }
        }
    
        .vs-table {
          border-collapse: separate;
          border-spacing: 0 1.3rem;
          padding: 0 1rem;
    
          tr {
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
           td {
  padding: 20px;
}

.table-row {
  display: flex; /* Make the row itself flexible */
  align-items: center; /* Vertically center cell content */
}

.table-row.flex-grow {  /* New class to control growth */
  flex-grow: 1; /* Allow the first cell to expand */
}

.product-name p {
  white-space: nowrap; /* Prevent wrapping by default */
  overflow: visible; /* Hide excess content with ellipsis */
  text-overflow: ellipsis;
  display: flex ; /* Allow content to shrink/grow horizontally */
  align-items: center;
}
.product-name p {
  max-height: 100rem; /* Adjust as needed */
}

.product-name p:hover {
  white-space: normal; /* Allow wrapping on hover (optional) */
}
.actions {
  white-space: nowrap; /* Maintain compact layout for icons */
}
.edit-actions {
  display: flex; /* Group edit icons together */
  justify-content: flex-end; /* Align icons to the right */
}
            td.td-check {
              padding: 20px !important;
            }
          }
        }
    
        .vs-table--thead {
          th {
            padding-top: 0;
            padding-bottom: 0;
    
            .vs-table-text {
              text-transform: uppercase;
              font-weight: 600;
            }
          }
          th.td-check {
            padding: 0 15px !important;
          }
          tr {
            background: none;
            box-shadow: none;
          }
        }
    
        .vs-table--pagination {
          justify-content: center;
        }

      }
    
    }
    textarea
      {
            width: 100%;
        height: 100px;
        padding: 12px 20px;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 5px;
        border-style: double;
        background-color: #f8f1f134;
        font-size: 16px;
        resize: none;
      }
      hr {
    display: block;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
</style>
  